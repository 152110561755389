<template>
  <div class="contact">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ContactContent />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContactContent from "./ContactContent.vue";
export default {
  components: {
    ContactContent,
  },
};
</script>