<template>
  <div class="contact__content">
    <div class="row justify-content-end">
      <div class="col-md-11">
        <div class="contact__card">
          <div class="team-item team-item--simple">
            <div class="team-item__inner contact__cards">
              <ContactCard />
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <ContactMap />
      </div>
    </div>
  </div>
</template>
<script>
import ContactForm from "./ContactForm.vue";
import ContactCard from "./ContactCard.vue";
import ContactMap from "./ContactMap.vue";
export default {
  components: {
    ContactMap,
    ContactCard,
    ContactForm,
  },
};
</script>