var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-form"},[_c('div',{staticClass:"contact-form__title"},[_vm._v(" "+_vm._s(_vm.$store.state.statics.HaveAQuestion)+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('input-row',{class:{
            invalid:
              _vm.$v.contactForm.name.$invalid && _vm.$v.contactForm.name.$dirty,
          },attrs:{"label":_vm.name},on:{"input":function($event){return _vm.$v.contactForm.name.$touch()},"blur":function($event){return _vm.$v.contactForm.name.$touch()}},model:{value:(_vm.contactForm.name),callback:function ($$v) {_vm.$set(_vm.contactForm, "name", $$v)},expression:"contactForm.name"}})],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('input-row',{class:{
            invalid:
              _vm.$v.contactForm.surname.$invalid &&
              _vm.$v.contactForm.surname.$dirty,
          },attrs:{"label":_vm.surname},on:{"input":function($event){return _vm.$v.contactForm.surname.$touch()},"blur":function($event){return _vm.$v.contactForm.surname.$touch()}},model:{value:(_vm.contactForm.surname),callback:function ($$v) {_vm.$set(_vm.contactForm, "surname", $$v)},expression:"contactForm.surname"}})],1)]),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('input-row',{class:{
            invalid:
              _vm.$v.contactForm.email.$invalid && _vm.$v.contactForm.email.$dirty,
          },attrs:{"label":_vm.email},on:{"input":function($event){return _vm.$v.contactForm.email.$touch()},"blur":function($event){return _vm.$v.contactForm.email.$touch()}},model:{value:(_vm.contactForm.email),callback:function ($$v) {_vm.$set(_vm.contactForm, "email", $$v)},expression:"contactForm.email"}})],1)]),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('input-row',{class:{
            invalid:
              _vm.$v.contactForm.phone.$invalid && _vm.$v.contactForm.phone.$dirty,
          },attrs:{"label":_vm.phone},on:{"input":function($event){return _vm.$v.contactForm.phone.$touch()},"blur":function($event){return _vm.$v.contactForm.phone.$touch()}},model:{value:(_vm.contactForm.phone),callback:function ($$v) {_vm.$set(_vm.contactForm, "phone", $$v)},expression:"contactForm.phone"}})],1)]),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('textarea-row',{class:{
            invalid:
              _vm.$v.contactForm.message.$invalid &&
              _vm.$v.contactForm.message.$dirty,
          },attrs:{"label":_vm.note},on:{"input":function($event){return _vm.$v.contactForm.message.$touch()},"blur":function($event){return _vm.$v.contactForm.message.$touch()}},model:{value:(_vm.contactForm.message),callback:function ($$v) {_vm.$set(_vm.contactForm, "message", $$v)},expression:"contactForm.message"}})],1)]),_c('div',{staticClass:"col-12 mb-4"},[_c('div',{staticClass:"accept-terms text-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.terms),expression:"contactForm.terms"}],staticClass:"mr-2",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.contactForm.terms)?_vm._i(_vm.contactForm.terms,null)>-1:(_vm.contactForm.terms)},on:{"change":function($event){var $$a=_vm.contactForm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.contactForm, "terms", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.contactForm, "terms", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.contactForm, "terms", $$c)}}}}),_c('terms')],1)]),_c('div',{staticClass:"col-12 text-center"},[_c('button',{staticClass:"button button--main",attrs:{"disabled":_vm.$v.contactForm.$invalid},on:{"click":_vm.send}},[_vm._v(" "+_vm._s(_vm.$store.state.statics.Send)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }