<template>
  <div class="contact-form">
    <div class="contact-form__title">
      {{ $store.state.statics.HaveAQuestion }}
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="name"
            v-model="contactForm.name"
            @input="$v.contactForm.name.$touch()"
            @blur="$v.contactForm.name.$touch()"
            :class="{
              invalid:
                $v.contactForm.name.$invalid && $v.contactForm.name.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row
            :label="surname"
            v-model="contactForm.surname"
            @input="$v.contactForm.surname.$touch()"
            @blur="$v.contactForm.surname.$touch()"
            :class="{
              invalid:
                $v.contactForm.surname.$invalid &&
                $v.contactForm.surname.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <input-row
            :label="email"
            v-model="contactForm.email"
            @input="$v.contactForm.email.$touch()"
            @blur="$v.contactForm.email.$touch()"
            :class="{
              invalid:
                $v.contactForm.email.$invalid && $v.contactForm.email.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <input-row
            :label="phone"
            v-model="contactForm.phone"
            @input="$v.contactForm.phone.$touch()"
            @blur="$v.contactForm.phone.$touch()"
            :class="{
              invalid:
                $v.contactForm.phone.$invalid && $v.contactForm.phone.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <textarea-row
            :label="note"
            v-model="contactForm.message"
            @input="$v.contactForm.message.$touch()"
            @blur="$v.contactForm.message.$touch()"
            :class="{
              invalid:
                $v.contactForm.message.$invalid &&
                $v.contactForm.message.$dirty,
            }"
          />
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="accept-terms text-left">
          <input v-model="contactForm.terms" type="checkbox" class="mr-2">
          <terms/>
        </div>
      </div>
      <div class="col-12 text-center">
        <button class="button button--main" @click="send" :disabled="$v.contactForm.$invalid">
          {{ $store.state.statics.Send }}
        </button>
      </div>
    </div>

  </div>
</template>
<script>
import { email, minLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import Terms from "../shared/Terms";

export default {
  components: { Terms },
  data() {
    return {
      contactForm: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        message: "",
        terms: false
      },
    };
  },
  methods: {
    send() {
      this.$store
        .dispatch("contact/sendMessage", this.contactForm)
        .then((res) => {
          if (res) {
            this.contactForm = {
              name: "",
              surname: "",
              email: "",
              phone: "",
              message: "",
            };
          }
        });
    },
  },
  computed: {
    ...mapState({
      name: (state) => state.statics.Name,
      surname: (state) => state.statics.Surname,
      phone: (state) => state.statics.Phone,
      email: (state) => state.statics.Email,
      note: (state) => state.statics.Note,
    }),
  },
  validations: {
    contactForm: {
      name: {
        required,
        minLength: minLength(3),
      },
      surname: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minLength: minLength(3)
      },
      message: {
        required,
        minLength: minLength(3)
      },
      terms: {
        checked(val) {
          return this.contactForm.terms ? true : val;
        }
      }
    },
  },
};
</script>
