<template>
  <div class="contact-page">
    <page-cover :cover="cover" v-if="cover" />
    <ContactPage />
  </div>
</template>
<script>
import PageCover from "@/components/shared/PageCover.vue";
import ContactPage from "../../components/contact/ContactPage.vue";
import { mapGetters } from "vuex";

export default {
  name: "contact",
  computed: {
    ...mapGetters({
      cover: "pageCover/getContact",
    }),
  },

  components: {
    PageCover,
    ContactPage,
  },
};
</script>