<template>
  <div class="row">
    <div class="col-lg-12" v-if="lang == 'de'">
      <div class="contact__map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.2096425539994!2d13.770708715753798!3d51.067679979565206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709cf32468688a1%3A0x7dbdad89eb13b2a5!2sArndtstra%C3%9Fe%204A%2C%2001099%20Dresden%2C%20Germany!5e0!3m2!1sen!2s!4v1624620693745!5m2!1sen!2s"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
        ></iframe>
      </div>
    </div>
    <div class="col-lg-12" v-else>
      <div class="contact__map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20060.152887887267!2d13.7709317943961!3d51.06196669516958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709cfb256414263%3A0x9cceb648fb61bcac!2sIGAplus!5e0!3m2!1sen!2saz!4v1709119333411!5m2!1sen!2saz"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      lang: (state) => state.lang,
    }),
  },
};
</script>