<template>
  <div class="contact-card" v-if="contact">
    <div class="contact-card__title">
      {{ $store.state.statics.Contact }}
    </div>
    <ul class="contact-card__row">
      <li class="contact-card__item">
        <b-icon icon="geo-fill" class="contact-card__icon"></b-icon>
        <span>{{ contact.address }}</span>
      </li>
      <li class="contact-card__item">
        <b-icon icon="phone-fill" class="contact-card__icon"></b-icon>
        <span>
          {{ contact.phone }}
        </span>
      </li>
      <li class="contact-card__item">
        <b-icon icon="envelope-fill" class="contact-card__icon"></b-icon>
        <span>
          {{ contact.email }}
        </span>
      </li>
      <li class="contact-card__item">
        <b-icon icon="location" class="contact-card__icon"></b-icon>
      </li>
    </ul>
    <div class="contact-card__socials">
      <Socials class="socials--light socials--contact" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Socials from "../../components/layout/Socials.vue";
export default {
  computed: {
    ...mapState({
      contact: (state) => state.contact.contacts,
    }),
  },
  components: {
    Socials,
  },
};
</script>
